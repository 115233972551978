import { z } from 'zod';

export enum IOAuthFlows {
  AuthCode = 'code',
  PKCE = 'pkce',
}

export const OAuthCredentialSchema = z.object({
  /** The access token associated with the OAuth credential */
  accessToken: z.string(),
  /** The refresh token associated with the OAuth credential */
  refreshToken: z.string(),
  /**  @deprecated - Should not be used and will be removed in future */
  idToken: z.string().optional(),
  /** Space separated list of scopes associated with the OAuth credential */
  scope: z.string(),
  /** Timestamp at which the access token expires */
  expiresAt: z.number().int().min(0).optional(),
  /** The authorization flow used to generate the OAuth credential */
  authFlow: z.nativeEnum(IOAuthFlows).optional(),
  /** The prefix to be used when setting Authorization headers during requests (e.g. Bearer) */
  tokenType: z.string().optional(),
});

export interface IOAuthCredential extends z.infer<typeof OAuthCredentialSchema> {}

export const ApiCredentialSchema = z.object({
  /** The name of the credential */
  name: z.string(),
  /** The API key associated with the credential */
  key: z.string(),
});

export interface IApiCredential extends z.infer<typeof ApiCredentialSchema> {}

export const OAuthClientCredentialSchema = z.object({
  /** The client ID associated with the OAuth credential */
  clientId: z.string(),
  /** The client secret associated with the OAuth credential */
  clientSecret: z.string().optional(),
});

export interface IOAuthClientCredential extends z.infer<typeof OAuthClientCredentialSchema> {}

export const BasicCredentialSchema = z.object({
  /** The username associated with the credential */
  username: z.string(),
  /** Securely stored password associated with the credential */
  password: z.string(),
});

export interface IBasicCredential extends z.infer<typeof BasicCredentialSchema> {}

export const CredentialSchema = z.union([
  OAuthCredentialSchema,
  ApiCredentialSchema,
  BasicCredentialSchema,
  OAuthClientCredentialSchema,
]);

export type Credential = z.infer<typeof CredentialSchema>;
