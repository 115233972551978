import {
  IFieldMappingConfigKey,
  IFieldMappingReferenceEntity,
  ITimesheetUpdateRule,
} from './timesheet-type.enum';
import { ConfigMapping } from '../../nodes';

export interface IPayPeriod {
  startDate: Date;
  endDate: Date;
  timezone: string;
}

export interface IEmployeeName {
  firstName?: string;
  lastName?: string;
}

export interface ITimesheetAuLine {
  key?: string;
  hours: number[];
  earningsRateId: string;
  earningsRateName?: string;
  trackingItemId?: string;
}

export interface ITimesheetV2Line {
  key?: string;
  date: Date;
  earningsRateId: string;
  earningsRateName?: string;
  numberOfUnits: number;
  trackingItemId?: string;
}

export type TimesheetLine = ITimesheetAuLine | ITimesheetV2Line;

export interface IBaseTimesheet {
  timesheetId?: string;
  employeeId: string;
  employeeName?: IEmployeeName;
  startDate: Date;
  endDate: Date;
  status?: string;
  payrollCalendarId: string;
  timesheetLines: unknown[];
}

export interface ITimesheetAu extends IBaseTimesheet {
  timesheetLines: ITimesheetAuLine[];
}

export interface ITimesheetV2 extends IBaseTimesheet {
  timesheetLines: ITimesheetV2Line[];
}

export interface ITimesheetTrackingCategoryOption {
  trackingOptionId?: string;
  name?: string;
}

export interface IFieldMapping {
  key: IFieldMappingConfigKey | string;
  kind: string;
  fieldId: string;
  columnId: string;
  referenceId: string;
  referenceEntity: IFieldMappingReferenceEntity;
}

export interface IXeroTimesheetNodeConfigSettings {
  timesheetExists?: ITimesheetUpdateRule;
}

export interface ITimesheetConfig {
  mappings: ConfigMapping[];
  settings: IXeroTimesheetNodeConfigSettings;
}

export interface IEmployee {
  employeeId: string;
  payrollCalendarId?: string;
  firstName: string;
  lastName: string;
  /** Employment end date (if exists) or fixed term end date (only applicable for NZ API) */
  endDate?: string;
}

export interface ITimesheetFieldMappings {
  dateMapping: IFieldMapping;
  hoursMapping: IFieldMapping;
  trackingItemMapping?: IFieldMapping;
}

export interface IPayrollCalendar {
  payrollCalendarId: string;
  name?: string;
  calendarType: string;
  startDate: string;
  updatedDate?: Date;
  referenceDate?: string;
}

export enum IXeroPayrollType {
  AU = 'au',
  UK = 'uk',
  NZ = 'nz',
}
