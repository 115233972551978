import Container from 'typedi';

import { logger } from '@site-mate/sitemate-lambda-utilities';

import { IInputDataMappingField, IInputDataValueData, MappingFieldTypes } from '../../../../models';
import { JsonataUtils } from '../../../../utils';
import { IInputDataResolver } from './inputdata-resolver.interface';
import { IFieldOption } from '../mappers/field-mapper.interface';

interface IObjectQuery {
  id: string;
  label: string;
}

/**
 * The default resolver for input data.
 * This will resolve the value using the jsonata query in the value data.
 */
export class DynamicInputDataResolver<TValue> implements IInputDataResolver<TValue> {
  public async resolve(valueData: IInputDataValueData, inputData: unknown): Promise<TValue | undefined> {
    const { query } = valueData;

    if (!query) {
      logger.warn(`${DynamicInputDataResolver.name}: Missing query`, { query });
      return undefined;
    }

    const jsonata = Container.get(JsonataUtils);

    try {
      const value = await jsonata.evaluate(inputData, query);
      return value as TValue;
    } catch (error: unknown) {
      logger.warn(`${DynamicInputDataResolver.name}: Failed to resolve jsonata query ${error}`);
      return undefined;
    }
  }

  public async getOptions(field: IInputDataMappingField, template: unknown) {
    const parsed = await Container.get(JsonataUtils).evaluate({ form: template }, field.filter ?? '');

    return Array.isArray(parsed) ? parsed.map(this.transform(field)) : [this.transform(field)(parsed)];
  }

  private transform(field: IInputDataMappingField) {
    return (item: IObjectQuery) =>
      ({
        id: item.id,
        label: item.label,
        valueData: {
          fieldId: field._id,
          fieldType: MappingFieldTypes.InputData,
          query: item.id,
        },
      } as IFieldOption);
  }

  public getOption(valueData: IInputDataValueData): string {
    return valueData.query as string;
  }
}
