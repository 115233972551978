/**
 * Helps throw the most relevant error messages
 *
 * @param {unknown} error - the error, may be an object or string on function call
 * @param {string} additionalMessage - optional, additional message providing further information
 */
const handleErrorMessage = (error: unknown, additionalMessage?: string): string => {
  if (typeof error === 'string') {
    return error;
  }
  if (error instanceof Error) {
    return error.message;
  }
  const base = `Error`;
  const defaultMessage = additionalMessage ? `${base} - ${additionalMessage}` : base;
  return defaultMessage;
};

export { handleErrorMessage };
