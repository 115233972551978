import { IMappingDefinition } from './mapping-definition.model';
import { IEntity } from '../../../shared';

export enum MappingConfigType {
  /** A mapping which is a single mapping item, containing no items array */
  Single = 'single',
  /** A mapping which contains an items array of single mapping items */
  MultipleSingle = 'multipleSingle',
  /** A mapping which contains an items array of a set of mapping items */
  MultipleSet = 'multipleSet',
}

/**
 * Represents a base mapping configuration
 */
export interface IBaseConfigMapping extends IEntity {
  /** The identifier of the mapping field */
  key: string;
  /** Flag that determines whether the mapping config is required */
  isRequired: boolean;
  /** Additional information about the mapping config */
  metadata?: any;
  /** The mapping definition type */
  type: MappingConfigType;
}

/**
 * Represents a single mapping configuration in the mappings array in a node.
 */
export interface ISingleConfigMapping extends IBaseConfigMapping {
  /** Flag that determines whether multiple instances of the mapping config is allowed */
  isMultiple: false;
  /** The mapping definition type */
  type: MappingConfigType.Single;
  /** The mapping definition for the source */
  source: IMappingDefinition;
  /** The mapping definition for the destination */
  destination: IMappingDefinition;
}

/**
 * Represents a single mapping item in a multiple mapping configuration.
 */
export interface IConfigMappingItem
  extends Pick<ISingleConfigMapping, '_id' | 'source' | 'destination' | 'metadata'> {}

/**
 * Represents a mapping within the configuration for a node.
 * Items is an array single mapping items.
 */
export interface IMultipleSingleConfigMapping extends IBaseConfigMapping {
  /** Flag that determines whether multiple instances of the mapping config is allowed */
  isMultiple: true;
  /** The mapping definition type */
  type: MappingConfigType.MultipleSingle;
  /** The list of mapping config if multiple instances is supported */
  items: IConfigMappingItem[];
}

/**
 * Represents an array of single mapping items in a multiple mapping configuration.
 */
export interface IConfigMappingSet extends IEntity {
  /** The list of mapping items for this set. Only supports single mapping for now. */
  mappings: ISingleConfigMapping[];
}

/**
 * Represents a mapping within the configuration for a node.
 * Items is an array of containing one or more sets of mapping items.
 */
export interface IMultipleSetConfigMapping extends IBaseConfigMapping {
  /** Flag that determines whether multiple instances of the mapping config is allowed */
  isMultiple: true;
  /** The mapping definition type */
  type: MappingConfigType.MultipleSet;
  /** The list of mapping config if multiple sets are supported */
  items: IConfigMappingSet[];
}

/**
 * Represents a mapping within the configuration for a node.
 */
export type ConfigMapping = ISingleConfigMapping | IMultipleSingleConfigMapping | IMultipleSetConfigMapping;
