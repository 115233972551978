/**
 * @deprecated use HttpStatusCodes on `@site-mate/sitemate-global-shared` instead
 *
 * Http Status Enum based on https://github.com/nestjs/nest/blob/master/packages/common/enums/http-status.enum.ts
 */

export enum HttpStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  UNPROCESSABLE_CONTENT = 422,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
}
