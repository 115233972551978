export enum INodeCategory {
  TRIGGER = 'trigger',
  ACTION = 'action',
  CONDITION = 'condition',
}

export const NodeCategoryDescriptions: Record<INodeCategory, string> = {
  [INodeCategory.TRIGGER]: 'Trigger',
  [INodeCategory.ACTION]: 'Action',
  [INodeCategory.CONDITION]: 'Condition',
};
