export enum IConnectionParameterType {
  Header = 'header',
  Query = 'query',
  Body = 'body',
}

export enum IConnectionParameterKeys {
  SitemateUserId = 'x-sitemate-user-id',
  QuickbooksRealmId = 'quickbooks-realm-id',
  XeroConnectionId = 'xero-connection-id',
  XeroPayrollType = 'xero-payroll-type',
  XeroTenantId = 'xero-tenant-id',
}
