import { FlowsitePlanTypes } from '@site-mate/sitemate-global-shared';

import { IFlowsiteIntegrationPlanLimits, IFlowsitePlanLimits } from './plan-limit.model';
import { FreeTrialPlanLimits } from './plan-types/free-trial';
import { FreemiumPlanLimits } from './plan-types/freemium';
import { PlatinumPlanLimits } from './plan-types/platinum';
import { PremiumPlanLimits } from './plan-types/premium';
import { ProPlanLimits } from './plan-types/pro';
import { StandardPlanLimits } from './plan-types/standard';
import { INodeType } from '../nodes';

export const FlowsitePlanLimitMappings: Record<FlowsitePlanTypes, IFlowsitePlanLimits> = {
  [FlowsitePlanTypes.FreeTrial]: FreeTrialPlanLimits, // TODO: establish plan limits for free trial/freemium
  [FlowsitePlanTypes.Freemium]: FreemiumPlanLimits,
  [FlowsitePlanTypes.Standard]: StandardPlanLimits,
  [FlowsitePlanTypes.Pro]: ProPlanLimits,
  [FlowsitePlanTypes.Premium]: PremiumPlanLimits,
  [FlowsitePlanTypes.Platinum]: PlatinumPlanLimits,
  [FlowsitePlanTypes.Enterprise]: PlatinumPlanLimits, // TODO: establish plan limits for enterprise/legacy
  [FlowsitePlanTypes.Legacy]: PlatinumPlanLimits,
};

export const NodeTypeIntegrationPlanLimitMapping: Record<INodeType, keyof IFlowsiteIntegrationPlanLimits> = {
  [INodeType.EXCEL_CONNECTOR_V1]: 'excelLimits',
  [INodeType.POWERBI_CONNECTOR_V1]: 'powerbiLimits',
  [INodeType.XERO_TIMESHEET_V1]: 'xeroLimits',
  [INodeType.XERO_INVOICE_V1]: 'xeroLimits',
  [INodeType.XERO_BILL_V1]: 'xeroLimits',
  [INodeType.QUICKBOOKS_INVOICE_V1]: 'quickbooksLimits',
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]: 'sharepointLimits',
};
