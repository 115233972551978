import { Flake } from '@site-mate/sitemate-global-shared';

/**
 * Contains helper methods for the path field
 */
export class PathService {
  /**
   * Extracts the resourceIds of the specified resources from the given path.
   *
   * @param path - The path to extract the values from
   * @param resources - The resource keys to extract values for
   * @returns - An object with keys matching the resource keys and values as array of extracted Ids
   *
   * @example Extracting the template ID and form ID from a path
   *
   * # Usage
   * const path = '/folder:1/template:2/form:3';
   * const resources = ['template', 'form'];
   *
   * const result = PathService.extractResourceIds(path, resourceToExtract);
   *
   * # Result
   * { template: ['2'], form: ['3'] }
   *
   */
  public static extractResourceIds<T extends string>(
    path: string | undefined,
    resources: T[],
  ): Record<T, string[]> {
    const extractedValues = {} as Record<T, string[]>;
    const trimmedPath = (path ?? '').trim();

    resources.forEach((resource) => {
      // eslint-disable-next-line @rushstack/security/no-unsafe-regexp
      const regex = new RegExp(`${String(resource)}:([^/]{1,})`, 'gm');
      extractedValues[resource] = [];

      let occurrence: RegExpExecArray | null;
      while ((occurrence = regex.exec(trimmedPath))) {
        const [, value] = occurrence;
        extractedValues[resource] = extractedValues[resource].concat(value);
      }
    });

    return extractedValues;
  }

  /**
   * Extracts the resourceId from the given path and prefix.
   * If no prefix is given, the last segment of the path is returned.
   *
   * @param path - The path to extract the value from
   * @returns - The extracted resourceId, or undefined if no resourceId was found
   *
   * @example Extracting the run ID from a path
   *
   * const path = '/ws_123/flo_456/ru_789';
   * const runId = PathService.extractId(path);
   * # Result:  'ru_789'
   *
   * const flowId = PathService.extractId(path, 'flo');
   * # Result:  'flo_456'
   */
  public static extractId(path: string, prefix?: typeof Flake.prefixes | string): string | undefined {
    const segments: string[] = path.split('/').filter((segment) => segment !== '');

    if (segments.length === 0) {
      return path;
    }

    if (!prefix) {
      return segments[segments.length - 1];
    }

    return segments.find((segment) => segment.startsWith(`${prefix}_`));
  }
}
