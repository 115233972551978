export enum IntegrationType {
  DASHPIVOT = 'dashpivot',
  XERO = 'xero',
  POWERBI = 'powerbi',
  EXCEL = 'excel',
  QUICKBOOKS = 'quickbooks',
  MICROSOFT = 'microsoft',
  SITEMATE_OPEN_API = 'sitemate-open-api',
}

export const IntegrationTypeNameMapping: Record<IntegrationType, string> = {
  [IntegrationType.DASHPIVOT]: 'Dashpivot',
  [IntegrationType.XERO]: 'Xero',
  [IntegrationType.POWERBI]: 'Power BI',
  [IntegrationType.EXCEL]: 'Excel',
  [IntegrationType.QUICKBOOKS]: 'QuickBooks',
  [IntegrationType.MICROSOFT]: 'Microsoft',
  [IntegrationType.SITEMATE_OPEN_API]: 'Sitemate Open API',
};
