{
  "$ref": "Billing-v2#/definitions/Billing",
  "definitions": {
    "Billing": {
      "title": "Billing",
      "type": "object",
      "properties": {
        "planType": {
          "$ref": "Billing-v2#/definitions/FlowsitePlanTypes",
          "description": "The billing plan type: 'Free Trial', 'Freemium', 'Legacy', 'Standard', 'Pro', 'Enterprise'",
          "title": "planType"
        },
        "subscriptionStatus": {
          "description": "The status of the billing: 'Free Trial', 'Expired Trial', 'Paid', 'Overdue Invoice', 'Paused', 'Churned'",
          "enum": ["Churned", "Expired Trial", "Free Trial", "Overdue Invoice", "Paid", "Paused"],
          "type": "string",
          "title": "subscriptionStatus"
        },
        "initialSubscriptionDate": {
          "description": "Date when customer subscribed",
          "type": "string",
          "format": "date",
          "nullable": true,
          "title": "initialSubscriptionDate"
        },
        "trialEndDate": {
          "description": "Date when trial subscription ends",
          "type": "string",
          "format": "date",
          "nullable": true,
          "title": "trialEndDate"
        },
        "subscriptionTerm": {
          "description": "Duration of subscription",
          "anyOf": [
            {
              "enum": ["1 Year", "2 Years", "3 Years", "5 Years", "Monthly"],
              "type": "string"
            },
            { "type": "null" }
          ],
          "title": "subscriptionTerm"
        },
        "billingCycle": {
          "description": "Frequency of when customer is billed",
          "anyOf": [
            {
              "enum": ["Annual", "Monthly", "Quarterly", "Semi-Annual"],
              "type": "string"
            },
            { "type": "null" }
          ],
          "title": "billingCycle"
        },
        "paymentTermDay": {
          "description": "Payment term quantifier, used in conjunction with payment term context",
          "nullable": true,
          "type": "number",
          "title": "paymentTermDay"
        },
        "paymentTermContext": {
          "description": "Payment term qualifier, user in conjunction with payment term day",
          "anyOf": [
            {
              "enum": [
                "day(s) after the end of the invoice month",
                "day(s) after the invoice date",
                "of the current month",
                "of the following month"
              ],
              "type": "string"
            },
            { "type": "null" }
          ],
          "title": "paymentTermContext"
        },
        "paymentMethod": {
          "description": "Mode of payment used by customer",
          "anyOf": [
            {
              "enum": ["Credit/Debit Card", "Direct Debit", "Electronic Bank Transfer"],
              "type": "string"
            },
            { "type": "null" }
          ],
          "title": "paymentMethod"
        },
        "currency": {
          "description": "Currency paid by customer",
          "anyOf": [{ "enum": ["AUD", "EUR", "GBP", "USD", "ZAR"], "type": "string" }, { "type": "null" }],
          "title": "currency"
        },
        "_id": { "type": "string", "title": "_id" },
        "createdAt": {
          "type": "string",
          "format": "date-time",
          "title": "createdAt"
        },
        "updatedAt": {
          "type": "string",
          "format": "date-time",
          "title": "updatedAt"
        },
        "createdBy": { "type": "string", "title": "createdBy" },
        "updatedBy": { "type": "string", "title": "updatedBy" }
      },
      "additionalProperties": false,
      "required": ["_id", "createdAt", "createdBy", "planType", "updatedAt", "updatedBy"]
    },
    "FlowsitePlanTypes": {
      "title": "FlowsitePlanTypes",
      "enum": ["Enterprise", "Free Trial", "Freemium", "Legacy", "Platinum", "Premium", "Pro", "Standard"],
      "type": "string"
    }
  },
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "Billing-v2"
}
