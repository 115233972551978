import { z } from 'zod';

import { IConnectionParameterType, IConnectionParameterKeys } from './connection-parameter.enum';

export const ConnectionParameterSchema = z.object({
  _id: z.string().ulid(),
  type: z.nativeEnum(IConnectionParameterType),
  key: z.nativeEnum(IConnectionParameterKeys),
  value: z.string(),
});

export interface IConnectionParameter extends z.infer<typeof ConnectionParameterSchema> {}
