{
  "$ref": "Run-v1#/definitions/Run",
  "definitions": {
    "Run": {
      "title": "Run",
      "type": "object",
      "properties": {
        "flowVersion": {
          "description": "The version of the Flow that the Run is based off.",
          "type": "number",
          "title": "flowVersion"
        },
        "counter": {
          "description": "A counter keeping track of the most recent run created for that flow.",
          "type": "number",
          "title": "counter"
        },
        "executionCount": {
          "description": "A counter keeping track of the number of times that the Run has commenced. Starts at 001.",
          "default": 1,
          "type": "number",
          "title": "executionCount"
        },
        "name": {
          "description": "The name of the Run, taken from the Flow name.",
          "type": "string",
          "title": "name"
        },
        "description": {
          "description": "A description of the Run, taken from the Flow description.",
          "type": "string",
          "title": "description"
        },
        "schemaVersion": {
          "description": "The schema version the connection was created against - uses the schema Id",
          "type": "string",
          "title": "schemaVersion"
        },
        "status": {
          "$ref": "Run-v1#/definitions/RunStatus",
          "description": "The status of the Flow, either Pending, Paused, Success or Failed.",
          "title": "status"
        },
        "nodes": {
          "description": "An array containing each Node which is part of the Run, taken from the Flow.",
          "type": "array",
          "items": { "$ref": "Run-v1#/definitions/Node" },
          "title": "nodes"
        },
        "edges": {
          "description": "An array containing each Edge which is part of the Run, taken from the Flow.",
          "type": "array",
          "items": { "$ref": "Run-v1#/definitions/Edge" },
          "title": "edges"
        },
        "logs": {
          "description": "An array containing any Logs generated during a Run.",
          "type": "array",
          "items": { "$ref": "Run-v1#/definitions/Log" },
          "title": "logs"
        },
        "errors": {
          "description": "An array containing any Errors generated during a Run.",
          "type": "array",
          "items": { "$ref": "Run-v1#/definitions/Error" },
          "title": "errors"
        },
        "metadata": {
          "description": "Any Flow specific metadata generated, internally generated.",
          "title": "metadata"
        },
        "customMetadata": {
          "description": "Custom metadata associated with the Run, provided by the User (e.g. name of user that triggered the Flow).",
          "title": "customMetadata"
        },
        "_id": { "type": "string", "title": "_id" },
        "createdBy": { "type": "string", "title": "createdBy" },
        "updatedBy": { "type": "string", "title": "updatedBy" },
        "createdAt": {
          "type": "string",
          "format": "date-time",
          "title": "createdAt"
        },
        "updatedAt": {
          "type": "string",
          "format": "date-time",
          "title": "updatedAt"
        }
      },
      "required": [
        "_id",
        "counter",
        "createdAt",
        "createdBy",
        "description",
        "edges",
        "errors",
        "executionCount",
        "flowVersion",
        "logs",
        "name",
        "nodes",
        "schemaVersion",
        "status",
        "updatedAt",
        "updatedBy"
      ]
    },
    "RunStatus": {
      "title": "RunStatus",
      "enum": ["failed", "paused", "pending", "success"],
      "type": "string"
    },
    "Node": {
      "title": "Node",
      "type": "object",
      "properties": {
        "integrationId": {
          "description": "A reference to the integration that this Node is built for (e.g. Xero).",
          "type": "string",
          "title": "integrationId"
        },
        "type": {
          "$ref": "Run-v1#/definitions/NodeType",
          "description": "The type of the Node, such as Xero_Timesheet_v1.",
          "title": "type"
        },
        "title": {
          "description": "The title of the Node, User provided.",
          "type": "string",
          "title": "title"
        },
        "description": {
          "description": "A description of what the Node does.",
          "type": "string",
          "title": "description"
        },
        "status": {
          "$ref": "Run-v1#/definitions/NodeStatus",
          "description": "The status of the Node, either Pending, Skipped, Success or Failed.",
          "title": "status"
        },
        "connectionId": {
          "description": "A reference to the specific authentication parameters to be used by this Node.",
          "type": "string",
          "title": "connectionId"
        },
        "category": {
          "$ref": "Run-v1#/definitions/NodeCategory",
          "description": "The category of the Node, indicating its functionality.",
          "title": "category"
        },
        "config": {
          "description": "Configuration params set by the User, such as mappings, filters and conditions.",
          "title": "config"
        },
        "input": {
          "description": "The input data that the Node will process.",
          "title": "input"
        },
        "output": {
          "description": "The expected output of the Node.",
          "title": "output"
        },
        "logs": {
          "description": "An array containing any Logs generated by the Node.",
          "type": "array",
          "items": { "$ref": "Run-v1#/definitions/Log" },
          "title": "logs"
        },
        "errors": {
          "description": "An array containing any Errors generated by the Node.",
          "type": "array",
          "items": { "$ref": "Run-v1#/definitions/Error" },
          "title": "errors"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": [
        "_id",
        "category",
        "config",
        "connectionId",
        "description",
        "errors",
        "input",
        "integrationId",
        "logs",
        "output",
        "status",
        "title",
        "type"
      ]
    },
    "NodeType": {
      "title": "NodeType",
      "enum": [
        "excel-connector-monolithic-v1",
        "microsoft-sharepoint-form-upload-monolithic-v1",
        "powerbi-connector-monolithic-v1",
        "quickbooks-invoice-monolithic-v1",
        "xero-bill-monolithic-v1",
        "xero-invoice-monolithic-v1",
        "xero-timesheet-monolithic-v1"
      ],
      "type": "string"
    },
    "NodeStatus": {
      "title": "NodeStatus",
      "enum": ["failed", "pending", "skipped", "success"],
      "type": "string"
    },
    "NodeCategory": {
      "title": "NodeCategory",
      "enum": ["action", "condition", "trigger"],
      "type": "string"
    },
    "Log": {
      "title": "Log",
      "type": "object",
      "properties": {
        "transactionId": {
          "description": "A composite Id, made up of the Run Id and Run executionCount, which represents the execution of a Run.",
          "type": "string",
          "title": "transactionId"
        },
        "nodeId": {
          "description": "A reference to the Node associated with the Log, if exists.",
          "type": "string",
          "title": "nodeId"
        },
        "edgeId": {
          "description": "A reference to the Edge associated with the Log, if exists.",
          "type": "string",
          "title": "edgeId"
        },
        "type": {
          "description": "The type of the Log.",
          "type": "string",
          "title": "type"
        },
        "metadata": {
          "description": "Contains any additional information generated for the Log, such as status of the Node.",
          "title": "metadata"
        },
        "createdAt": {
          "type": "string",
          "format": "date-time",
          "title": "createdAt"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "createdAt", "transactionId", "type"]
    },
    "Error": {
      "title": "Error",
      "type": "object",
      "properties": {
        "nodeId": {
          "description": "A reference to the Node associated with the Log, if exists.",
          "type": "string",
          "title": "nodeId"
        },
        "edgeId": {
          "description": "A reference to the Edge associated with the Log, if exists.",
          "type": "string",
          "title": "edgeId"
        },
        "type": {
          "description": "The type of the Error",
          "type": "string",
          "title": "type"
        },
        "level": {
          "$ref": "Run-v1#/definitions/ErrorLevel",
          "description": "The level of severity of the Error.",
          "default": "error",
          "title": "level"
        },
        "status": {
          "description": "The statuscode of the Error.",
          "type": "number",
          "title": "status"
        },
        "message": {
          "description": "The error message of the Error.",
          "type": "string",
          "title": "message"
        },
        "metadata": {
          "description": "A detailed report of the Error.",
          "title": "metadata"
        },
        "createdAt": {
          "type": "string",
          "format": "date-time",
          "title": "createdAt"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "createdAt", "level", "message", "type"]
    },
    "ErrorLevel": {
      "title": "ErrorLevel",
      "enum": ["error", "warn"],
      "type": "string"
    },
    "Edge": {
      "title": "Edge",
      "type": "object",
      "properties": {
        "from": {
          "description": "The Node from which the Edge starts.",
          "type": "string",
          "title": "from"
        },
        "to": {
          "description": "The Node at which the Edge ends.",
          "type": "string",
          "title": "to"
        },
        "label": {
          "description": "A label to attach to the Edge.",
          "type": "string",
          "title": "label"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "from", "to"]
    }
  },
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "Run-v1"
}
