import jsonata from 'jsonata';
import { Service } from 'typedi';

@Service()
export class JsonataUtils {
  public async evaluate(data: unknown, jsonataExpression: string): Promise<any> {
    const expression = jsonata(jsonataExpression);
    return expression.evaluate(data);
  }

  public generateJsonataQueryString(key: any, value: any): string {
    return `${key} = ${typeof value === 'string' ? `"${value}"` : `${value}`}`;
  }
}
