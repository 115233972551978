import { IFlowsitePlanLimits } from '../plan-limit.model';

export const StandardPlanLimits = {
  monthlyRuns: 100,
  runOverageBatchSize: 100,
  dashpivotLimits: {
    isIntegrationEnabled: true,
    maxWorkspaces: 1,
  },
  xeroLimits: {
    isIntegrationEnabled: true,
    maxTenants: 1,
    isPersonalTimesheetsEnabled: true,
    isCrewTimesheetsEnabled: true,
    isInvoiceAndBillsEnabled: false, // todo explore how to handle this as addon
  },
  quickbooksLimits: {
    maxCompanies: 1,
    isIntegrationEnabled: true,
  },
  excelLimits: {
    isIntegrationEnabled: true,
    isManualFlowTriggerEnabled: false,
  },
  powerbiLimits: {
    isIntegrationEnabled: false,
    isManualFlowTriggerEnabled: false,
  },
  sharepointLimits: {
    isIntegrationEnabled: false,
  },
  openApiLimits: {
    isIntegrationEnabled: false,
  },
} as IFlowsitePlanLimits;
