export enum IOptionSourceKeys {
  DashpivotTemplate = 'dashpivot-template',
  DashpivotTemplates = 'dashpivot-templates',
  MicrosoftSharePointSites = 'microsoft-sharepoint-sites',
  MicrosoftSharePointSiteDrives = 'microsoft-sharepoint-site-drives',
  MicrosoftSharePointSiteDriveFolders = 'microsoft-sharepoint-site-drive-folders',
  XeroAccountingAccounts = 'xero-accounting-accounts',
  XeroAccountingTrackingCategories = 'xero-accounting-tracking-categories',
  XeroPayrollAU = 'xero-payrollau-earningsrates',
  XeroPayrollUKNZ = 'xero-payrolluknz-earningsrates',
}
