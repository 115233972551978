import billingSchema from './billing-v2.schema.json';
import connectionSchema from './connection-v1.schema.json';
import flowSchema from './flow-v2.schema.json';
import mappingConfigSchema from './mappingconfig-v2.schema.json';
import runSchema from './run-v1.schema.json';
import { ConnectionLatestSchemaVersion } from '../models';

export const Schemas = {
  run: runSchema,
  flow: flowSchema,
  connection: connectionSchema,
  billing: billingSchema,
  mappingConfig: mappingConfigSchema,
};

export const LatestSchemaVersions = {
  run: runSchema.$id,
  flow: flowSchema.$id,
  connection: ConnectionLatestSchemaVersion,
  billing: billingSchema.$id,
  mappingConfig: mappingConfigSchema.$id,
};
