import { IntegrationType } from '../models';

const pkceIntegrations = [IntegrationType.DASHPIVOT, IntegrationType.XERO];

/**
 * Check if the integration type supports PKCE Flow for authorization.
 *
 * @param type - The integration type to check
 * @returns true if the integration supports PKCE Flow, false if it only supports
 * non-PKCE/standard Authorization Code Flow
 */
export const isPkceSupported = (type: IntegrationType) => pkceIntegrations.includes(type);
