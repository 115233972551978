import { IFlowsitePlanLimits } from '../plan-limit.model';

// TODO: Assess if free trial plan can be removed / adjusted after FS-1139
export const FreeTrialPlanLimits = {
  monthlyRuns: 100,
  runOverageBatchSize: 100,
  dashpivotLimits: {
    isIntegrationEnabled: true,
  },
  xeroLimits: {
    isIntegrationEnabled: true,
    isPersonalTimesheetsEnabled: true,
    isCrewTimesheetsEnabled: true,
    isInvoiceAndBillsEnabled: true,
  },
  excelLimits: {
    isIntegrationEnabled: true,
  },
  powerbiLimits: {
    isIntegrationEnabled: true,
  },
  sharepointLimits: {
    isIntegrationEnabled: true,
  },
  openApiLimits: {
    isIntegrationEnabled: false,
  },
} as IFlowsitePlanLimits;
