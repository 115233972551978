import { IFieldMapper, IFieldOption } from './field-mapper.interface';
import {
  IDropdownMappingField,
  IDropdownOption,
  IDropdownValueData,
  MappingFieldTypes,
} from '../../../../models';
import { MaybePromise } from '../../../../utils';

export class DropdownFieldMapper implements IFieldMapper<IDropdownValueData, string> {
  public getValue<TValue extends string>(valueData: IDropdownValueData): MaybePromise<TValue | undefined> {
    const { option } = valueData;

    if (!option) {
      return undefined;
    }

    return option.value as TValue;
  }

  public getLabel(valueData: IDropdownValueData): MaybePromise<string | undefined> {
    const { option } = valueData;

    return option?.label;
  }

  public getOptions(field: IDropdownMappingField, sourceData: unknown): Promise<IFieldOption[]> {
    if (!Array.isArray(sourceData)) {
      return Promise.resolve([]);
    }

    const options = sourceData as IDropdownOption[];
    return Promise.resolve(options.map(this.transform(field)));
  }

  private transform(field: IDropdownMappingField) {
    return (option: IDropdownOption) =>
      ({
        id: option.value,
        label: option.label,
        valueData: {
          fieldId: field._id,
          fieldType: MappingFieldTypes.Dropdown,
          option: {
            id: option.value,
            value: option.value,
            label: option.label,
          },
        },
      } as IFieldOption);
  }

  public getOption(valueData: IDropdownValueData): string {
    return valueData?.option?.value;
  }
}
