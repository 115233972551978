import { DynamicInputDataResolver } from './dynamic-inputdata-resolver';
import { IInputDataResolver } from './inputdata-resolver.interface';
import { DashpivotFieldResolver } from './dashpivot-field-resolver';
import { DashpivotTableColumnFieldResolver } from './dashpivot-tablecolumn-resolver';
import { CustomResolvers } from '../../../../models';

/**
 * Simple factory to create an input data resolver based on the custom resolver type.
 */
export class InputDataResolverFactory {
  public static create<TValue>(customResolver?: CustomResolvers): IInputDataResolver {
    if (customResolver === CustomResolvers.DashpivotField) {
      return new DashpivotFieldResolver();
    }

    if (customResolver === CustomResolvers.DashpivotTableColumnField) {
      return new DashpivotTableColumnFieldResolver();
    }

    return new DynamicInputDataResolver<TValue>();
  }
}
