import { StandardPlanLimits } from './standard';
import { IFlowsitePlanLimits } from '../plan-limit.model';

export const ProPlanLimits = {
  ...StandardPlanLimits,
  monthlyRuns: 500,
  runOverageBatchSize: 200,
  dashpivotLimits: {
    ...StandardPlanLimits.dashpivotLimits,
    maxWorkspaces: undefined,
  },
  xeroLimits: {
    ...StandardPlanLimits.xeroLimits,
    maxTenants: undefined,
    isInvoiceAndBillsEnabled: true, // todo explore how to handle this as addon
  },
  quickbooksLimits: {
    ...StandardPlanLimits.quickbooksLimits,
    maxCompanies: undefined,
  },
  excelLimits: {
    ...StandardPlanLimits.excelLimits,
    isIntegrationEnabled: true,
    isManualFlowTriggerEnabled: true,
  },
  powerbiLimits: {
    ...StandardPlanLimits.powerbiLimits,
    isIntegrationEnabled: true,
    isManualFlowTriggerEnabled: true,
  },
  sharepointLimits: {
    ...StandardPlanLimits.sharepointLimits,
    isIntegrationEnabled: true,
  },
  openApiLimits: {
    isIntegrationEnabled: true,
  },
} as IFlowsitePlanLimits;
