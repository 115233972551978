import { z } from 'zod';

import { CredentialSchema } from './connection-credential.model';
import { ConnectionParameterSchema } from './connection-parameter.model';
import { IConnectionStatus } from './connection-status.enum';
import { IConnectionType } from './connection-type.enum';
import { BaseEntitySchema } from '../shared/entity';

/**
 * Latest schema version for Connection.
 * This is used to determine the schema version the connection was created against.
 * Increment this value whenever the schema is updated and the changes are not backwards compatible.
 */
export const ConnectionLatestSchemaVersion = 'Connection-v1';

export const ConnectionSchema = z
  .object({
    /** The type of authorization (BASIC, OAUTH, API_KEY) */
    type: z.nativeEnum(IConnectionType),
    /**
     * The name of the connection automatically set based on the integration.
     * Usually, it is derived from the tenant/organisation name associated with the connection.
     */
    name: z.string(),
    /** The unique identifier for the integration */
    integrationId: z.string().ulid(),
    /** The schema version the connection was created against - uses the schema Id */
    schemaVersion: z.string(),
    /** The current status of the auth (e.g. connected, disconnected, reconnecting) */
    status: z.nativeEnum(IConnectionStatus),
    /** The specific credentials associated with the connection based on the connection type */
    credential: CredentialSchema,
    /** Additional parameters for the auth (e.g. X_Tenant_ID) */
    parameters: ConnectionParameterSchema.array().optional(),
  })
  .merge(BaseEntitySchema);

export interface IConnection extends z.infer<typeof ConnectionSchema> {}

const CreateConnectionQuickbooksMetadataSchema = z.object({
  /** The unique identifier for Quickbooks Company */
  realmId: z.string(),
});

export interface ICreateConnectionQuickbooksMetadata
  extends z.infer<typeof CreateConnectionQuickbooksMetadataSchema> {}

// This can be set as a union if there are multiple metadata types in the future
export const CreateConnectionMetadataSchema = CreateConnectionQuickbooksMetadataSchema;

export type CreateConnectionMetadata = z.infer<typeof CreateConnectionMetadataSchema>;

export const ConnectionWithMetadataSchema = ConnectionSchema.merge(
  z.object({
    name: z.string().optional(),
    /** Additional data required to build connection parameters */
    metadata: CreateConnectionMetadataSchema.optional(),
  }),
);

export interface IConnectionWithMetadata extends z.infer<typeof ConnectionWithMetadataSchema> {}
