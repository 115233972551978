import Ajv, { ErrorObject, SchemaObject } from 'ajv';
import { Service } from 'typedi';
import addFormats from 'ajv-formats';

export interface IValidationErrorSummary {
  propertyName?: any;
  instancePath?: any;
  params?: any;
  keyword?: string;
  schemaPath?: string;
  message: string;
}
export interface IValidationResult {
  isValid: boolean;
  errors: IValidationErrorSummary[] | undefined;
}

@Service()
export class SchemaValidationService {
  private readonly validator: Ajv;

  public constructor() {
    this.validator = new Ajv({
      allErrors: true,
      verbose: true,
      strictTypes: true,
      strict: false,
    });
    addFormats(this.validator);
  }

  public validate(schema: SchemaObject, data: unknown): IValidationResult {
    if (!this.validator.validateSchema(schema)) {
      return {
        isValid: false,
        errors: this.createErrorSummaries(this.validator.errors!),
      };
    }

    if (schema.$schema !== 'http://json-schema.org/draft-07/schema#') {
      return {
        isValid: false,
        errors: [{ message: 'Schema not marked as Draft-07 - see http://json-schema.org/draft-07/schema#' }],
      };
    }

    const schemaValidator = this.validator.compile(schema);

    schemaValidator(data);

    if (schemaValidator.errors) {
      return {
        isValid: false,
        errors: this.createErrorSummaries(schemaValidator.errors),
      };
    }

    return {
      isValid: true,
      errors: undefined,
    };
  }

  private createErrorSummaries(errors: ErrorObject[]) {
    const errorSummaries: IValidationErrorSummary[] = [];

    errors.forEach((error) =>
      errorSummaries.push({
        propertyName: error.propertyName,
        instancePath: error.instancePath,
        params: error.params,
        keyword: error.keyword,
        schemaPath: error.schemaPath,
        message: error.message || 'No error message',
      }),
    );

    return errorSummaries;
  }
}
