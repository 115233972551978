import Container from 'typedi';

import { logger } from '@site-mate/sitemate-lambda-utilities';

import { IInputDataResolver } from './inputdata-resolver.interface';
import {
  CustomResolvers,
  IInputDataMappingField,
  MappingFieldTypes,
  MappingValueData,
} from '../../../../models';
import { JsonataUtils } from '../../../../utils';
import { IFieldOption } from '../mappers/field-mapper.interface';

/**
 * Metadata in ValueData that contains the information about the selected Dashpivot Table Column
 */
export interface IDashpivotTableColumnMetadata {
  dashpivotFieldId: string;
  dashpivotColumnId: string;
}

/**
 * Output of the Dashpivot Table Column Resolver
 */
export interface IDashpivotTableColumnValue {
  customResolver: CustomResolvers.DashpivotTableColumnField;
  dashpivotColumnId: string;
  dashpivotTableField: unknown;
  dashpivotTableCells: unknown;
}

/**
 * Output of the Dashpivot Table Column Resolver
 * @param value - The value to check
 * @returns True if the value is a Dashpivot Table Column, false otherwise
 */
export const isDashpivotTableColumn = (value: any): value is IDashpivotTableColumnValue => {
  return value?.customResolver === CustomResolvers.DashpivotTableColumnField;
};

const ObjectQuery: string = `{
  "id": id,
  "label": %.description & ' - ' & heading,
  "metadata":{
    "dashpivotFieldId": %.id,
    "dashpivotColumnId": id
  }
}`;
interface IObjectQuery {
  id: string;
  label: string;
  metadata: {
    dashpivotFieldId: string;
    dashpivotColumnId: string;
  };
}

/**
 * Custom resolver for Dashpivot Table Column
 */
export class DashpivotTableColumnFieldResolver implements IInputDataResolver<IDashpivotTableColumnValue> {
  public async resolve(
    valueData: MappingValueData,
    inputData: unknown,
  ): Promise<IDashpivotTableColumnValue | undefined> {
    const { metadata } = valueData || {};

    const { dashpivotFieldId, dashpivotColumnId } = metadata as IDashpivotTableColumnMetadata;

    if (!dashpivotFieldId || !dashpivotColumnId) {
      return undefined;
    }

    const fieldQuery = `form.items[_id="${dashpivotFieldId}"]`;
    const tableCellsQuery = `rows[].columns[headerColumnId="${dashpivotColumnId}"]`;

    const jsonata = Container.get(JsonataUtils);

    try {
      const tableField = await jsonata.evaluate(inputData, fieldQuery);

      if (!tableField) {
        logger.warn(`${DashpivotTableColumnFieldResolver.name}: Unable to find table field`, {
          fieldQuery,
          dashpivotFieldId,
        });
        return undefined;
      }

      // TODO: Validate if column id exists on column
      const tableCells = await jsonata.evaluate(tableField, tableCellsQuery);

      if (!tableCells) {
        logger.warn(`${DashpivotTableColumnFieldResolver.name}: Unable to find table cells`, {
          tableCellsQuery,
          tableField,
          dashpivotColumnId,
          dashpivotFieldId,
        });
        return undefined;
      }

      return {
        customResolver: CustomResolvers.DashpivotTableColumnField,
        dashpivotColumnId,
        dashpivotTableField: tableField,
        dashpivotTableCells: tableCells,
      };
    } catch (error: unknown) {
      logger.warn(`${DashpivotTableColumnFieldResolver.name}: Failed to resolve jsonata query ${error}`);
      return undefined;
    }
  }

  public async getOptions(field: IInputDataMappingField, template: unknown) {
    const parsed: IObjectQuery | IObjectQuery[] = await Container.get(JsonataUtils).evaluate(
      { form: template },
      `${field.filter}.${ObjectQuery}`,
    );

    if (!parsed) {
      return [];
    }

    return Array.isArray(parsed) ? parsed.map(this.transform(field)) : [this.transform(field)(parsed)];
  }

  private transform(field: IInputDataMappingField) {
    return (item: IObjectQuery) =>
      ({
        id: item.id,
        label: item.label as string,
        valueData: {
          fieldId: field._id,
          fieldType: MappingFieldTypes.InputData,
          customResolver: field.customResolver,
          metadata: item.metadata,
        },
      } as IFieldOption);
  }

  public getOption(valueData: MappingValueData): string {
    return valueData?.metadata?.dashpivotColumnId;
  }
}
