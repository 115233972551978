import { z } from 'zod';

import { Flake, ZodUtility } from '@site-mate/sitemate-global-shared';

import { ConnectionSchema, ConnectionWithMetadataSchema } from './connection.model';

export const ConnectionIdParamSchema = z.object({
  connectionId: ZodUtility.createFlakeIdSchema(Flake.prefixes.Connection),
});

const ConnectionIdResponseSchema = ConnectionSchema.pick({
  _id: true,
});

/** List Connections DTO */
export const ListConnectionsRequestDtoSchema = z.object({
  integrationId: z.string().optional(),
  includeIntegrationIds: z.string().optional(),
  excludeIntegrationIds: z.string().optional(),
});
export interface IListConnectionsRequestDto extends z.infer<typeof ListConnectionsRequestDtoSchema> {}

export const ListConnectionsResponseDtoSchema = ConnectionSchema.pick({
  _id: true,
  name: true,
  integrationId: true,
  type: true,
  status: true,
  parameters: true,
  createdBy: true,
  createdAt: true,
  updatedBy: true,
  updatedAt: true,
});
export interface IListConnectionsResponseDto extends z.infer<typeof ListConnectionsResponseDtoSchema> {}

/** Create Connection DTO */
export const CreateConnectionRequestDtoSchema = ConnectionWithMetadataSchema.pick({
  type: true,
  integrationId: true,
  credential: true,
  metadata: true,
});
export interface ICreateConnectionRequestDto extends z.infer<typeof CreateConnectionRequestDtoSchema> {}

export const CreateConnectionResponseDtoSchema = ConnectionIdResponseSchema;
export interface ICreateConnectionResponseDto extends z.infer<typeof CreateConnectionResponseDtoSchema> {}

/** Reconnect Connection DTO */
export const ReconnectConnectionRequestDtoSchema = ConnectionSchema.pick({
  credential: true,
  type: true,
});
export interface IReconnectConnectionRequestDto extends z.infer<typeof ReconnectConnectionRequestDtoSchema> {}

export const ReconnectConnectionResponseDtoSchema = ConnectionIdResponseSchema;
export interface IReconnectConnectionResponseDto
  extends z.infer<typeof ReconnectConnectionResponseDtoSchema> {}

/** Delete Connection DTO */
export const DeleteConnectionResponseDtoSchema = ConnectionIdResponseSchema;
export interface IDeleteConnectionResponseDto extends z.infer<typeof DeleteConnectionResponseDtoSchema> {}
