import { HttpStatus } from '../../../utils';

export class MappingConfigError extends Error {
  public statusCode: number = HttpStatus.BAD_REQUEST;

  public constructor(message: string) {
    super(message);
    this.name = 'MappingConfigError';
  }
}
