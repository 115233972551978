export enum IXeroInvoiceFieldMappingConfigKey {
  Contact = 'Contact',
  InvoiceDate = 'InvoiceDate',
  Description = 'Description',
  LineItems = 'LineItems',
  Quantity = 'Quantity',
  Amount = 'Amount',
  Account = 'Account',
  TrackingCategory1 = 'TrackingCategory1',
  TrackingCategory2 = 'TrackingCategory2',
}
