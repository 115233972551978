export enum INodeType {
  XERO_TIMESHEET_V1 = 'xero-timesheet-monolithic-v1',
  XERO_INVOICE_V1 = 'xero-invoice-monolithic-v1',
  XERO_BILL_V1 = 'xero-bill-monolithic-v1',
  POWERBI_CONNECTOR_V1 = 'powerbi-connector-monolithic-v1',
  EXCEL_CONNECTOR_V1 = 'excel-connector-monolithic-v1',
  QUICKBOOKS_INVOICE_V1 = 'quickbooks-invoice-monolithic-v1',
  MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1 = 'microsoft-sharepoint-form-upload-monolithic-v1',
}

export const INodeTypeIntercomMapping: Record<INodeType, string> = {
  [INodeType.XERO_TIMESHEET_V1]:
    'https://intercom.help/dashpivot/en/articles/8271454-setting-up-xero-timesheets-integration',
  [INodeType.XERO_INVOICE_V1]:
    'https://intercom.help/dashpivot/en/articles/8535164-xero-invoicing-integration',
  [INodeType.XERO_BILL_V1]: 'https://intercom.help/dashpivot/en/articles/8819535-xero-bills-integration',
  [INodeType.POWERBI_CONNECTOR_V1]:
    'https://intercom.help/dashpivot/en/articles/8418772-setting-up-microsoft-power-bi-integration',
  [INodeType.EXCEL_CONNECTOR_V1]:
    'https://intercom.help/dashpivot/en/articles/8636278-microsoft-excel-integration',
  [INodeType.QUICKBOOKS_INVOICE_V1]:
    'https://intercom.help/dashpivot/en/articles/9172700-quickbooks-invoicing-integration',
  // TODO: Add SharePoint integration intercom link
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]: '',
};
