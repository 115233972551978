/**
 * Constructs a type from a subset of keys in Type, with all keys set to optional
 */
export type PartialOptional<T, K extends keyof T> = Partial<Pick<T, K>>;

export type OptionalId<T> = Omit<T, '_id'> & { _id?: string };

export type Maybe<T> = T | undefined;

/**
 * Return type that guarantees non-null types when the checked parameter is known to defined during compile time
 */
export type TypeOrUndefined<Check, ReturnedType> = Check extends undefined | null ? undefined : ReturnedType;

export type MaybePromise<T> = T | Promise<T>;

export type Result<T, E> = ({ ok: true; value: T } | { ok: false; errorValue: E }) & Record<string, unknown>;

/**
 * For each property in T, including its children, make it optional
 */
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const Ok = <T>(data: T, props?: Record<string, any>): Result<T, never> => ({
  ok: true,
  value: data,
  ...(props || {}),
});

export const Fail = <E>(errorValue: E, props?: Record<string, unknown>): Result<never, E> => ({
  ok: false,
  errorValue,
  ...(props || {}),
});
