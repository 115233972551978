{
  "$ref": "MappingConfig-v2#/definitions/MappingConfig",
  "definitions": {
    "MappingConfig": {
      "title": "MappingConfig",
      "anyOf": [
        { "$ref": "MappingConfig-v2#/definitions/SingleMappingConfig" },
        { "$ref": "MappingConfig-v2#/definitions/MultipleSingleMappingConfig" },
        { "$ref": "MappingConfig-v2#/definitions/MultipleSetMappingConfig" }
      ]
    },
    "SingleMappingConfig": {
      "title": "SingleMappingConfig",
      "type": "object",
      "properties": {
        "isMultiple": {
          "description": "Flag that determines whether multiple instances of the mapping config is allowed",
          "type": "boolean",
          "const": false,
          "title": "isMultiple"
        },
        "type": {
          "description": "The mapping definition type",
          "type": "string",
          "const": "single",
          "title": "type"
        },
        "source": {
          "$ref": "MappingConfig-v2#/definitions/MappingDefinition",
          "description": "The mapping definition for the source",
          "title": "source"
        },
        "destination": {
          "$ref": "MappingConfig-v2#/definitions/MappingDefinition",
          "description": "The mapping definition for the destination",
          "title": "destination"
        },
        "key": {
          "description": "The identifier of the mapping field",
          "type": "string",
          "title": "key"
        },
        "isRequired": {
          "description": "Flag that determines whether the mapping config is required",
          "type": "boolean",
          "title": "isRequired"
        },
        "metadata": {
          "description": "Additional information about the mapping config",
          "title": "metadata"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "destination", "isMultiple", "isRequired", "key", "source", "type"]
    },
    "MappingDefinition": {
      "title": "MappingDefinition",
      "type": "object",
      "properties": {
        "isEditable": {
          "description": "Flag whether the definition can be edited by the user",
          "type": "boolean",
          "title": "isEditable"
        },
        "fields": {
          "description": "Represents the available mapping fields",
          "type": "array",
          "items": { "$ref": "MappingConfig-v2#/definitions/MappingField" },
          "title": "fields"
        },
        "valueData": {
          "description": "Represents the value of the mapping selected by the user",
          "anyOf": [
            { "$ref": "MappingConfig-v2#/definitions/StaticValueData" },
            { "$ref": "MappingConfig-v2#/definitions/InputDataValueData" },
            { "$ref": "MappingConfig-v2#/definitions/DropdownValueData" }
          ],
          "title": "valueData"
        },
        "defaultValueData": {
          "description": "Represents the default value of the mapping",
          "anyOf": [
            { "$ref": "MappingConfig-v2#/definitions/StaticValueData" },
            { "$ref": "MappingConfig-v2#/definitions/InputDataValueData" },
            { "$ref": "MappingConfig-v2#/definitions/DropdownValueData" }
          ],
          "title": "defaultValueData"
        }
      },
      "required": ["fields", "isEditable"]
    },
    "MappingField": {
      "title": "MappingField",
      "anyOf": [
        { "$ref": "MappingConfig-v2#/definitions/InputDataMappingField" },
        { "$ref": "MappingConfig-v2#/definitions/DropdownMappingField" }
      ]
    },
    "InputDataMappingField": {
      "description": "Represents an input data mapping field.",
      "title": "InputDataMappingField",
      "type": "object",
      "properties": {
        "fieldType": {
          "description": "The unique identifier for the input data mapping field",
          "type": "string",
          "const": "inputData",
          "title": "fieldType"
        },
        "label": {
          "description": "The text to display for the input field. It could be undefined when a custom resolver is used to resolve the label.",
          "type": "string",
          "title": "label"
        },
        "filter": {
          "description": "The jsonata expression to use for filtering the input data",
          "type": "string",
          "title": "filter"
        },
        "customResolver": {
          "description": "The custom resolver for resolving custom values.",
          "enum": ["dashpivotField", "dashpivotTableColumnField"],
          "type": "string",
          "title": "customResolver"
        },
        "integrationId": {
          "description": "The integration id associated with the field",
          "type": "string",
          "title": "integrationId"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "fieldType"]
    },
    "DropdownMappingField": {
      "description": "Represents a dropdown mapping field.",
      "title": "DropdownMappingField",
      "type": "object",
      "properties": {
        "fieldType": {
          "description": "The type of the dropdown mapping field",
          "type": "string",
          "const": "dropdown",
          "title": "fieldType"
        },
        "optionType": {
          "description": "The type of dropdown options: `dynamic` or manual",
          "enum": ["dynamic", "manual"],
          "type": "string",
          "title": "optionType"
        },
        "optionSource": {
          "description": "The source key to use for querying the dynamic options",
          "type": "string",
          "title": "optionSource"
        },
        "options": {
          "description": "The list of options",
          "type": "array",
          "items": { "$ref": "MappingConfig-v2#/definitions/DropdownOption" },
          "title": "options"
        },
        "integrationId": {
          "description": "The integration id associated with the field",
          "type": "string",
          "title": "integrationId"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "fieldType", "optionType"]
    },
    "DropdownOption": {
      "description": "Represents an option in a dropdown field.",
      "title": "DropdownOption",
      "type": "object",
      "properties": {
        "id": {
          "description": "The unique identifier for the dropdown option",
          "type": "string",
          "title": "id"
        },
        "label": {
          "description": "The text to display for the dropdown option",
          "type": "string",
          "title": "label"
        },
        "value": {
          "description": "The value of the dropdown option",
          "type": "string",
          "title": "value"
        }
      },
      "required": ["id", "label", "value"]
    },
    "StaticValueData": {
      "description": "Represents the static value data.\nThis is used when the mapping definition does not need to be mapped (e.g. when `isDisabled` is true)\nand we only need to display a static label.",
      "title": "StaticValueData",
      "type": "object",
      "properties": {
        "label": { "type": "string", "title": "label" },
        "integrationId": { "type": "string", "title": "integrationId" },
        "metadata": { "title": "metadata" }
      },
      "required": ["label"]
    },
    "InputDataValueData": {
      "description": "Represents the value data for an input data mapping field.",
      "title": "InputDataValueData",
      "type": "object",
      "properties": {
        "fieldId": {
          "description": "The unique identifier of the selected mapping field",
          "type": "string",
          "title": "fieldId"
        },
        "fieldType": {
          "description": "The type of the mapping field selected",
          "type": "string",
          "const": "inputData",
          "title": "fieldType"
        },
        "customResolver": {
          "description": "The custom resolver used to resolve custom values, if applicable",
          "enum": ["dashpivotField", "dashpivotTableColumnField"],
          "type": "string",
          "title": "customResolver"
        },
        "query": {
          "description": "The jsonata expression to use for resolving the value",
          "type": "string",
          "title": "query"
        },
        "metadata": { "title": "metadata" }
      },
      "required": ["fieldId", "fieldType"]
    },
    "DropdownValueData": {
      "description": "Represents the value data for a dropdown mapping field.",
      "title": "DropdownValueData",
      "type": "object",
      "properties": {
        "fieldId": {
          "description": "The unique identifier of the selected mapping field",
          "type": "string",
          "title": "fieldId"
        },
        "fieldType": {
          "description": "The type of the mapping field selected",
          "type": "string",
          "const": "dropdown",
          "title": "fieldType"
        },
        "option": {
          "$ref": "MappingConfig-v2#/definitions/DropdownOption",
          "description": "The selected option in the dropdown",
          "title": "option"
        },
        "metadata": { "title": "metadata" }
      },
      "required": ["fieldId", "fieldType", "option"]
    },
    "MultipleSingleMappingConfig": {
      "title": "MultipleSingleMappingConfig",
      "type": "object",
      "properties": {
        "isMultiple": {
          "description": "Flag that determines whether multiple instances of the mapping config is allowed",
          "type": "boolean",
          "const": true,
          "title": "isMultiple"
        },
        "type": {
          "description": "The mapping definition type",
          "type": "string",
          "const": "multipleSingle",
          "title": "type"
        },
        "items": {
          "description": "The list of mapping config if multiple instances is supported",
          "type": "array",
          "items": {
            "$ref": "MappingConfig-v2#/definitions/MappingConfigItem"
          },
          "title": "items"
        },
        "key": {
          "description": "The identifier of the mapping field",
          "type": "string",
          "title": "key"
        },
        "isRequired": {
          "description": "Flag that determines whether the mapping config is required",
          "type": "boolean",
          "title": "isRequired"
        },
        "metadata": {
          "description": "Additional information about the mapping config",
          "title": "metadata"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "isMultiple", "isRequired", "items", "key", "type"]
    },
    "MappingConfigItem": {
      "title": "MappingConfigItem",
      "type": "object",
      "properties": {
        "source": {
          "$ref": "MappingConfig-v2#/definitions/MappingDefinition",
          "description": "The mapping definition for the source",
          "title": "source"
        },
        "metadata": {
          "description": "Additional information about the mapping config",
          "title": "metadata"
        },
        "_id": { "type": "string", "title": "_id" },
        "destination": {
          "$ref": "MappingConfig-v2#/definitions/MappingDefinition",
          "description": "The mapping definition for the destination",
          "title": "destination"
        }
      },
      "required": ["_id", "destination", "source"]
    },
    "MultipleSetMappingConfig": {
      "title": "MultipleSetMappingConfig",
      "type": "object",
      "properties": {
        "isMultiple": {
          "description": "Flag that determines whether multiple instances of the mapping config is allowed",
          "type": "boolean",
          "const": true,
          "title": "isMultiple"
        },
        "type": {
          "description": "The mapping definition type",
          "type": "string",
          "const": "multipleSet",
          "title": "type"
        },
        "items": {
          "description": "The list of mapping config if multiple sets are supported",
          "type": "array",
          "items": { "$ref": "MappingConfig-v2#/definitions/MappingConfigSet" },
          "title": "items"
        },
        "key": {
          "description": "The identifier of the mapping field",
          "type": "string",
          "title": "key"
        },
        "isRequired": {
          "description": "Flag that determines whether the mapping config is required",
          "type": "boolean",
          "title": "isRequired"
        },
        "metadata": {
          "description": "Additional information about the mapping config",
          "title": "metadata"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "isMultiple", "isRequired", "items", "key", "type"]
    },
    "MappingConfigSet": {
      "title": "MappingConfigSet",
      "type": "object",
      "properties": {
        "mappings": {
          "description": "The list of mapping items for this set. Only supports single mapping for now.",
          "type": "array",
          "items": {
            "$ref": "MappingConfig-v2#/definitions/SingleMappingConfig"
          },
          "title": "mappings"
        },
        "_id": { "type": "string", "title": "_id" }
      },
      "required": ["_id", "mappings"]
    }
  },
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "MappingConfig-v2"
}
