import { QUnitType, OpUnitType, ManipulateType } from 'dayjs';

import { dayjs } from './dayjs.util';
import { TypeOrUndefined } from './typescript.util';
import { ISODate, ISODateTime } from '../models/shared/iso-date';

export const nowISODate = (): ISODateTime => new Date().toISOString();

export enum DateFormats {
  ShortDate = 'YYYY-MM-DD',
}

export const displayedDateFormat = 'MMM D YYYY';

export const toLocalDateTime = (dateISO: string | Date, tz: string): Date =>
  dayjs(dateISO).tz(tz).utc(true).toDate();

export const getDifference = (
  baseDate: Date,
  compareToDate: Date,
  unit: QUnitType | OpUnitType,
  float = false,
): number => dayjs(baseDate).diff(compareToDate, unit, float);

export const isSameDay = (leftDate: Date, rightDate: Date): boolean =>
  dayjs(leftDate).isSame(dayjs(rightDate), 'day');

export const formatDate = (date: Date | string, format: string, isUtc?: boolean): string => {
  const dayjsDate = isUtc ? dayjs.utc(date) : dayjs(date);
  return dayjsDate.format(format);
};

export const toISODate = <T extends string | Date | null | undefined>(
  date: T,
): TypeOrUndefined<T, ISODate> => {
  if (!date) {
    return undefined as TypeOrUndefined<T, ISODate>;
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return formatDate(dateObj, DateFormats.ShortDate, true) as TypeOrUndefined<T, ISODate>;
};

export const addDays = (date: Date, unit: ManipulateType, value: number): Date =>
  dayjs(date).add(value, unit).toDate();

export const subtractDays = (date: Date, unit: ManipulateType, value: number): Date =>
  dayjs(date).subtract(value, unit).toDate();

export const isValidDate = (date: Date | string): boolean => dayjs(date).isValid();

export const isValidISODate = (date: string): boolean =>
  dayjs(date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', true).isValid();

export const toDate = (date: string): Date => {
  if (!isValidISODate(date)) {
    throw new Error('Invalid date');
  }

  return new Date(date);
};

export const parseUtcDate = (date: string): Date => dayjs.utc(date).toDate();

export const toUnixTimestamp = (date: Date): number => dayjs(date).unix();

export const isDateBetween = (
  date: Date,
  startDate: Date,
  endDate: Date,
  unit: OpUnitType = 'day',
  isExclusive: boolean = false,
): boolean => dayjs(date).isBetween(startDate, endDate, unit, isExclusive ? '()' : '[]');

export const isDateBefore = <T extends Date | string>(baseDate: T, compareToDate: T): boolean =>
  dayjs(baseDate).isBefore(compareToDate, 'date');

export const isDateAfter = <T extends Date | string>(baseDate: T, compareToDate: T): boolean =>
  dayjs(baseDate).isAfter(compareToDate, 'date');

/**
 * Returns the current timezone of the user.
 * @returns The user's timezone identifier (e.g. 'Australia/Sydney')
 */
export const guessUserTimezone = (): string => dayjs.tz.guess();
