import { MappingValueData, MappingFieldTypes } from '../../../../models';

import { IFieldMapper } from './field-mapper.interface';
import { DropdownFieldMapper } from './dropdown-field-mapper';
import { InputDataFieldMapper } from './inputdata-field-mapper';

export class FieldMapperFactory {
  public static create<TValueData extends MappingValueData>(
    fieldType: MappingFieldTypes,
  ): IFieldMapper<TValueData> {
    if (fieldType === MappingFieldTypes.InputData) {
      return new InputDataFieldMapper() as IFieldMapper<TValueData>;
    }

    if (fieldType === MappingFieldTypes.Dropdown) {
      return new DropdownFieldMapper() as IFieldMapper<TValueData>;
    }

    throw new Error(`Field Mapper for type ${fieldType} not implemented`);
  }
}
