/**
 * Flags which set behaviour if the same timesheet already exists in Xero:
 *
 * 1. Skip instructs flowsite to skip that timesheet, and not modify the timesheet in Xero at all.
 * 2. Overwrite instructs flowsite to replace the timesheet in Xero with the new timesheet.
 * 3. Combine instructs flowsite to combine the timesheets and submit the combined timesheet to Xero.
 */
export enum ITimesheetUpdateRule {
  SKIP = 'skip',
  OVERWRITE = 'overwrite',
  COMBINE = 'combine',
}

export enum IFieldMappingConfigKey {
  TrackingItem = 'TrackingItem',
  Date = 'Date',
  SingleDate = 'SingleDate',
  Hours = 'Hours',
  Employee = 'Employee',
}

export enum IFieldMappingReferenceEntity {
  EarningsRatesId = 'EarningsRatesId',
}
