import { Service } from 'typedi';

import { FieldMapperFactory } from './mappers/field-mapper.factory';
import { MappingConfigError } from './mapping-config.error';
import {
  IMappingDefinition,
  IMultipleSingleConfigMapping,
  INodeConfig,
  ConfigMapping,
  MappingFieldTypes,
  MappingConfigType,
  IConfigMappingSet,
  IMultipleSetConfigMapping,
  ISingleConfigMapping,
  MappingField,
  IDropdownMappingField,
} from '../../../models';
import { Fail, Maybe, Ok, Result } from '../../../utils';

export interface IMappingValue<TValue> {
  mappedValue: TValue;
  mappedLabel?: string;
}

export type MappingValueResult<TValue> = Result<IMappingValue<TValue>, MappingConfigError>;

@Service()
export class MappingConfigService {
  /**
   * Retrieves a single mapping definition for a given key
   *
   * @param key - The identifier of the mapping to retrieve
   * @param config - The node config to retrieve the mapping from
   * @returns - The mapping config for the given key
   */
  public getMappingConfigForKey(
    key: string,
    config: INodeConfig | IConfigMappingSet,
  ): Maybe<ISingleConfigMapping> {
    return config.mappings.find(
      (item: ConfigMapping) => item.key === key && !item.isMultiple,
    ) as Maybe<ISingleConfigMapping>;
  }

  /**
   * Retrieves all mapping definitions for a given type
   *
   * @param type - The identifier of the mapping to retrieve
   * @param config - The node config to retrieve the mapping from
   * @returns - The mapping configs for the given type
   */
  public getMappingConfigsForType<T>(type: MappingConfigType, config: INodeConfig): Maybe<T[]> {
    const { mappings } = config;
    return mappings.filter((item: ConfigMapping) => item.type === type) as Maybe<T[]>;
  }

  /**
   * Retrieves all mapping definitions for a given type
   *
   * @param type - The identifier of the mapping to retrieve
   * @param config - The node config to retrieve the mapping from
   * @returns - The mapping configs for the given type
   */
  public getMappingConfigsForKeyAndType<T>(
    key: string,
    type: MappingConfigType,
    config: INodeConfig,
  ): Maybe<T[]> {
    const { mappings } = config;
    return mappings.filter((item: ConfigMapping) => item.type === type && item.key === key) as Maybe<T[]>;
  }

  /**
   * Retrieves multi-mapping definition for a given key
   *
   * @param key - The identifier of the mapping to retrieve
   * @param config - The node config to retrieve the mapping from
   * @returns - The mapping config for the given key
   */
  public getMultiMappingConfigForKey(key: string, config: INodeConfig): Maybe<IMultipleSingleConfigMapping> {
    return config.mappings.find(
      (item: ConfigMapping) => item.key === key && !!item.isMultiple,
    ) as Maybe<IMultipleSingleConfigMapping>;
  }

  /**
   * Retrieves multiple set definition for a given key
   *
   * @param key - The identifier of the mapping to retrieve
   * @param config - The node config to retrieve the mapping from
   * @returns - The mapping config for the given key
   */
  public getMultipleSetConfigForKey(key: string, config: INodeConfig): Maybe<IMultipleSetConfigMapping> {
    return config.mappings.find(
      (item: ConfigMapping) => item.key === key && item.type === MappingConfigType.MultipleSet,
    ) as Maybe<IMultipleSetConfigMapping>;
  }

  /**
   * Resolves the value for a mapping definition
   *
   * @param mappingDefinition - The mapping definition to resolve
   * @param inputData - The input data to use when resolving the value
   * @returns - The resolved value and label for the mapping definition
   */
  public async getMappingValue<TValue>(
    mappingDefinition: IMappingDefinition,
    inputData: unknown,
  ): Promise<MappingValueResult<TValue>> {
    const valueData = mappingDefinition.valueData || mappingDefinition.defaultValueData;

    if (!valueData) {
      return Fail(new MappingConfigError('Missing value data'));
    }

    const { fieldType } = valueData;

    if (!fieldType) {
      return Fail(new MappingConfigError('Missing field type'));
    }

    const mapper = FieldMapperFactory.create(valueData.fieldType);

    try {
      const value = await mapper.getValue<TValue>(valueData, inputData);
      if (!value) {
        return Fail(new MappingConfigError('Value not found'));
      }

      const label = await mapper.getLabel(valueData, inputData);

      return Ok({ mappedValue: value, mappedLabel: label });
    } catch (error: unknown) {
      return Fail(new MappingConfigError('Unable to resolve value'));
    }
  }

  /**
   * Creates the field mapper based on the provided field type.
   *
   * @param fieldType - The field type to use for creating the field mapper
   * @returns - The field mapper, which throws an error if the field type is not implemented or undefined
   */
  public getFieldDataMapperFactory(fieldType: MappingFieldTypes) {
    return FieldMapperFactory.create(fieldType);
  }

  /**
   * Get the references of the embedded mapping fields inside the config mappings
   *
   * @param mappings config mappings containing the mapping fields to extract
   * @param integrationIdFilter optional integration id filter to apply on search results
   * @returns dynamic mapping fields embedded in the config mappings
   */
  public getDynamicMappingFields(mappings?: ConfigMapping[], integrationIdFilter?: string) {
    if (!mappings) {
      return [];
    }

    return mappings
      .map((mapping) => {
        if (!mapping.isMultiple) {
          return this.getDynamicMappingFieldsFromConfigMapping(mapping, integrationIdFilter);
        }
        if (mapping.type === MappingConfigType.MultipleSingle) {
          return mapping.items.map((item) =>
            this.getDynamicMappingFieldsFromConfigMapping(item, integrationIdFilter),
          );
        }
        return mapping.items.map((item) =>
          item.mappings.map((subItem) =>
            this.getDynamicMappingFieldsFromConfigMapping(subItem, integrationIdFilter),
          ),
        );
      })
      .flat(3);
  }

  private getDynamicMappingFieldsFromConfigMapping(
    configMapping: { source?: IMappingDefinition; destination?: IMappingDefinition },
    integrationId?: string,
  ) {
    const dynamicMappingFields = (mapping: MappingField): mapping is IDropdownMappingField => {
      const dynamicDropdownFilter =
        mapping.fieldType === MappingFieldTypes.Dropdown && mapping.optionType === 'dynamic';
      if (!integrationId) {
        return dynamicDropdownFilter;
      }
      return dynamicDropdownFilter && mapping.integrationId === integrationId;
    };

    const mappingFields: IDropdownMappingField[] = [];
    if (configMapping.source?.isEditable) {
      mappingFields.push(...configMapping.source.fields.filter(dynamicMappingFields));
    }
    if (configMapping.destination?.isEditable) {
      mappingFields.push(...configMapping.destination.fields.filter(dynamicMappingFields));
    }
    return mappingFields;
  }
}
