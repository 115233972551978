import { StandardPlanLimits } from './standard';
import { IFlowsitePlanLimits } from '../plan-limit.model';

export const FreemiumPlanLimits = {
  ...StandardPlanLimits,
  monthlyRuns: 50,
  xeroLimits: {
    ...StandardPlanLimits.xeroLimits,
    isCrewTimesheetsEnabled: false,
    isInvoiceAndBillsEnabled: false,
  },
  quickbooksLimits: {
    isIntegrationEnabled: false,
  },
} as IFlowsitePlanLimits;
