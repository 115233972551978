/**
 * Flags which set behaviour if the same invoice already exists in on the target integration:
 *
 * 1. Combine instructs flowsite to combine the invoices and submit the combined invoice.
 * 2. Overwrite instructs flowsite to replace the invoice with the new invoice.
 * 3. Create New instructs flowsite to create a new invoice on the target integration.
 */
export enum IInvoiceUpdateRule {
  Combine = 'combine',
  Overwrite = 'overwrite',
  CreateNew = 'create-new',
}

/**
 * Maps the invoice update rule to a user-friendly name.
 */
export const InvoiceUpdateRuleNameMapping = {
  [IInvoiceUpdateRule.Combine]: 'Combine',
  [IInvoiceUpdateRule.Overwrite]: 'Overwrite',
  [IInvoiceUpdateRule.CreateNew]: 'Create New',
};
