import { Frequency, Options, RRule } from 'rrule';
import { Service } from 'typedi';

import { logger } from '@site-mate/sitemate-lambda-utilities';

import { subtractDays } from './date.util';
import { Maybe } from './typescript.util';

export type RuleOptions = Options;
export { Frequency as RuleFrequency };

/**
 * Service for handling intervals.
 *
 * This service provides methods for calculating and managing intervals based on recurrence rules.
 */
@Service()
export class IntervalService {
  /**
   * Parses a recurrence rule and returns the parsed options.
   *
   * @param rule - The recurrence rule in iCalendar (RRULE) format.
   * @returns The parsed options.
   */
  public parseRule(rule: string): Maybe<Partial<RuleOptions>> {
    if (!rule) {
      return undefined;
    }

    const { origOptions } = RRule.fromString(rule);
    return origOptions;
  }

  /**
   * Generates a recurrence rule from the provided options.
   *
   * @param options - The options to generate the recurrence rule from.
   * @returns The recurrence rule in iCalendar (RRULE) format.
   */
  public generateRule(options: Maybe<Partial<RuleOptions>>): string {
    return new RRule(options).toString();
  }

  /**
   * Returns the start and end period for a given date and recurrence rule.
   *
   * @param date - The date for which the period is to be calculated.
   * @param rule - The recurrence rule in iCalendar (RRULE) format.
   * @returns An object containing the start and end dates of the period.
   * @throws Throws an error if the recurrence rule is invalid or if the start or end date could not be computed.
   */
  public getPeriodForDateAndRule(date: Date, rule: string): { start: Date; end: Date } {
    try {
      const rruleObj = RRule.fromString(rule);

      const startDate = rruleObj.before(date, true);
      const afterDate = rruleObj.after(date, false);

      if (!startDate || !afterDate) {
        logger.error('Start or end date could not be computed from provided rule', { rule, date });
        throw new Error('Start or end date could not be computed from provided rule');
      }

      const endDate = subtractDays(afterDate, 'day', 1);

      return {
        start: startDate,
        end: endDate,
      };
    } catch (error) {
      logger.error('Interval Service Error', { error, rule, date });
      throw error;
    }
  }

  /*
   * Gets the next interval for a given date and recurrence rule.
   *
   * @param rule rule - The recurrence rule in iCalendar (RRULE) format.
   * @param date date - The date based on which the next interval is to be calculated.
   * @returns date of the next interval computed from the provided rule and date
   * @throws Throws an error if the recurrence rule is invalid or if the start or end date could not be computed.
   */
  public getNextRecurrence(rule: string, date: Date = new Date()) {
    try {
      const rruleObj = RRule.fromString(rule);
      return rruleObj.after(date, false);
    } catch (error) {
      if (/Unknown\sRRULE\sproperty/.test(error.message)) {
        throw new Error('Invalid interval rule');
      }

      throw error;
    }
  }

  /**
   * Evaluates if a given recurrence rule string is valid.
   *
   * @param rule rule - The recurrence rule to be validated in iCalendar (RRULE) format.
   * @returns true if the rule is valid, false otherwise
   */
  public isValidRule(rule: string) {
    try {
      RRule.fromString(rule);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Evaluates if a given recurrence rule string has a next recurrence.
   *
   * @param rule rule - The recurrence rule to be validated in iCalendar (RRULE) format.
   * @returns true the rule has a next recurrence, false otherwise
   */
  public hasNextRecurrence(rule: string, date: Date = new Date()) {
    return !!this.getNextRecurrence(rule, date);
  }
}
