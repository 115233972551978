import { IFieldMapper, IFieldOption } from './field-mapper.interface';
import { IInputDataMappingField, IInputDataValueData } from '../../../../models';
import { MaybePromise } from '../../../../utils';
import { InputDataResolverFactory } from '../resolvers/inputdata-resolver.factory';

export class InputDataFieldMapper implements IFieldMapper<IInputDataValueData> {
  public async getValue<TValue>(
    valueData: IInputDataValueData,
    inputData?: unknown,
  ): Promise<TValue | undefined> {
    const { customResolver } = valueData;

    const resolver = InputDataResolverFactory.create<TValue>(customResolver);
    const value = await resolver.resolve(valueData, inputData);

    return value as TValue;
  }

  /**
   * TODO: Currently, the labels for input data fields are not being saved.
   * In the future, consider saving the appropriate label based on the resolver type.
   * This would enhance the context, e.g. displaying user-friendly error messages with the label instead of using the id.
   * For example, a dashpivot column field would use the `${TableName} - ${ColumnName}` as the label.
   * For now, it will simply return undefined as the label.
   *
   * @returns undefined - Not supported yet for input data fields.
   */
  public getLabel(): MaybePromise<string | undefined> {
    return undefined;
  }

  public getOptions(field: IInputDataMappingField, sourceData: unknown): Promise<IFieldOption[]> {
    const resolver = InputDataResolverFactory.create(field.customResolver);
    return resolver.getOptions(field, sourceData);
  }

  public getOption(valueData: IInputDataValueData): string {
    const resolver = InputDataResolverFactory.create(valueData.customResolver);
    return resolver.getOption(valueData);
  }
}
