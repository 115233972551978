import { z } from 'zod';

import { ISODateTime } from './iso-date';

// TODO: Remove duplication once we've fully migrated from jsonschema to zod

export const EntitySchema = z.object({
  _id: z.string(),
});

export interface IEntity {
  _id: string;
}

export const TimestampsSchema = z.object({
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export interface ITimestamps {
  /**
   * @type string
   * @format date-time
   */
  createdAt: ISODateTime;
  /**
   * @type string
   * @format date-time
   */
  updatedAt: ISODateTime;
}

export const AuthorsSchema = z.object({
  createdBy: z.string(),
  updatedBy: z.string(),
});

export interface IAuthors {
  createdBy: string;
  updatedBy: string;
}

export const BaseEntitySchema = EntitySchema.merge(TimestampsSchema).merge(AuthorsSchema);
