import Container from 'typedi';

import {
  CustomResolvers,
  IInputDataMappingField,
  IInputDataValueData,
  MappingFieldTypes,
  MappingValueData,
} from '../../../../models';
import { JsonataUtils } from '../../../../utils';
import { IInputDataResolver } from './inputdata-resolver.interface';
import { logger } from '@site-mate/sitemate-lambda-utilities';
import { IFieldOption } from '../mappers/field-mapper.interface';

/**
 * Metadata in ValueData that contains the information about the selected Dashpivot Field
 */
export interface IDashpivotFieldMetadata {
  dashpivotFieldId: string;
}

/**
 * Output of the Dashpivot Field Resolver
 */
export interface IDashpivotFieldValue {
  customResolver: CustomResolvers.DashpivotField;
  dashpivotField: unknown;
}

/**
 * Type guard to check if the value is a Dashpivot Field
 * @param value - The value to check
 * @returns True if the value is a Dashpivot Field, false otherwise
 */
export const isDashpivotField = (value: any): value is IDashpivotFieldValue => {
  return value?.customResolver === CustomResolvers.DashpivotField;
};

const ObjectQuery: string = `{
  "id": id,
  "label": description,
  "metadata":{
    "dashpivotFieldId": id
  }
}`;
interface IObjectQuery {
  id: string;
  label: string;
  metadata: {
    dashpivotFieldId: string;
  };
}

/**
 * Custom resolver for Dashpivot Field
 */
export class DashpivotFieldResolver implements IInputDataResolver<IDashpivotFieldValue> {
  public async resolve(
    valueData: IInputDataValueData,
    inputData: unknown,
  ): Promise<IDashpivotFieldValue | undefined> {
    const { metadata } = valueData || {};

    const { dashpivotFieldId } = metadata as IDashpivotFieldMetadata;

    if (!dashpivotFieldId) {
      return undefined;
    }

    const query = `form.items[_id="${dashpivotFieldId}"]`;

    const jsonata = Container.get(JsonataUtils);

    try {
      const field = await jsonata.evaluate(inputData, query);
      if (!field) {
        return undefined;
      }

      return {
        customResolver: CustomResolvers.DashpivotField,
        dashpivotField: field,
      };
    } catch (error: unknown) {
      logger.warn(`${DashpivotFieldResolver.name}: Failed to resolve jsonata query ${error}`);
      return undefined;
    }
  }

  public async getOptions(field: IInputDataMappingField, template: unknown) {
    const parsed: IObjectQuery | IObjectQuery[] = await Container.get(JsonataUtils).evaluate(
      { form: template },
      `${field.filter}.${ObjectQuery}`,
    );
    if (!parsed) {
      return [];
    }
    return Array.isArray(parsed) ? parsed.map(this.transform(field)) : [this.transform(field)(parsed)];
  }

  private transform(field: IInputDataMappingField) {
    return (item: IObjectQuery) =>
      ({
        id: item.id,
        label: item.label,
        valueData: {
          fieldId: field._id,
          fieldType: MappingFieldTypes.InputData,
          customResolver: field.customResolver,
          metadata: item.metadata,
        },
      } as IFieldOption);
  }

  public getOption(valueData: MappingValueData): string {
    return valueData?.metadata?.dashpivotFieldId;
  }
}
